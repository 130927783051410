import { Button } from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";

import TaskListItem from "../partials/task-list-item"
import MyDrawer from "../partials/drawer"

export default function Home(props) {

    const [all_tasks, set_all_tasks] = useState([])
    const [all_tasks_for_user, set_all_tasks_for_user] = useState([])




    const fetch_all_tasks = () => {
        console.log("fetch all tasks")

        /* Get All Tasks */
        fetch(process.env.REACT_APP_API_URL+"/GetAllTasks")
        .then(response => response.json())
        .then(data => set_all_tasks(data))

        /* Get Tasks for User */
        fetch(process.env.REACT_APP_API_URL+"/GetAllTasksForAssignee/"+props.username)
        .then(response => response.json())
        .then(data => set_all_tasks_for_user(data))
    }


    const navigate = useNavigate();

    const navigate_create_task = () => {
        console.log("navigate to createtask")
        navigate("/createtask");
    }

    useEffect(() => {
        fetch_all_tasks()
    }, [])

    return (
        <div className="home-container">
            <MyDrawer></MyDrawer>
            <div className="scroll-view-container">
                <h1 className="App-Title">Hallo {props.username}</h1>
                <h2 className="text-center">Deine Aufgaben</h2>
                <div id="task_list">
                { all_tasks_for_user.length === 0 && all_tasks.length > 0
                    ? <h2 className="text-center" style={{margin: "1em 0"}}>Übernimm eine Aufgabe aus der unteren Liste.</h2>
                    : <h1 className="text-center" style={{margin: "1em 0"}}>Alles geschafft! 🥳</h1>
                }
                    { all_tasks_for_user.map((task,index) => (
                        <TaskListItem 
                            key={task.id} 
                            title={task.title}
                            text={task.description}
                            assignee={task.assignee} 
                            id={task.id} 
                            username={props.username}
                            fetch_all_tasks={fetch_all_tasks}
                            index={index}
                        />
                    ))}
                </div>
                <br></br>
                <hr></hr>
                <br></br>
                <h2 className="text-center">Alle Aufgaben</h2>
                <div id="task_list">
                { all_tasks.length === 0
                    ? <h1 className="text-center" style={{margin: "1em 0"}}>Alles geschafft! 🥳</h1>
                    : <div />
                }
                    { all_tasks.map((task, index) => (
                        <TaskListItem 
                            key={task.id} 
                            title={task.title} 
                            text={task.description}
                            assignee={task.assignee} 
                            id={task.id} 
                            username={props.username}
                            fetch_all_tasks={fetch_all_tasks}
                            index={index}
                        />
                    ))}
                </div>
            </div>
            <div id="action_button_panel">
                <Button variant="contained" onClick={navigate_create_task}>Neue Aufgabe</Button>
            </div>

            
        </div>
    )
}