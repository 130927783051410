import { TextField, Button, Checkbox, FormControlLabel } from "@mui/material";
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import MyDrawer from "../partials/drawer";
import TemplateListItem from "../partials/template-list-item";



export default function CreateTask(props) {
    
    const [title, set_title] = useState()
    const [description, set_description] = useState()
    const [templates, set_templates] = useState([])
    const [use_as_template, set_as_tempalte] = useState(false)



    const navigate = useNavigate();
    
    const click_create = async () => {
        /* Check if Title is set */
        if (title === "" || title === null) {
            return
        }
        
        /* Catch null values */
        if (description == null) {
            set_description("")
        }

        if (use_as_template === true) {
            await save_as_template()
        }

        /* Create Task in Backend */
        fetch(process.env.REACT_APP_API_URL+"/AddTask", {
            method: 'POST',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "Title": title,
                    "Description": description,
                    "Assignee": "Niemand",
                    "Status": "open",
                })
        })
        /* Navigate back to home */
        .then(navigate("/"))
        .catch( err => {
            console.log(err)
        })
    }

    const get_templates = () => {
        try {
        fetch(process.env.REACT_APP_API_URL+"/GetTemplates")
            .then(response => response.json())
            .then(data => set_templates(data))
            .then(data => console.log(data))
        } catch {}
    }    

    useEffect(() => {
        get_templates()
    }, [])

    const save_as_template = async () => {
        await fetch(process.env.REACT_APP_API_URL+"/AddTemplate", {
            method: 'POST',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "Title": title,
                    "Description": description,
                })
        })
    }

    return (
        <div className="home-container">
            <MyDrawer />
            <div id="create-task-page">
                <div className="new-task-form">
                    <h1 className="text-center">Aufgabe erstellen</h1>
                    <TextField 
                        id="title" 
                        label="Title"
                        onChange={(value) => set_title(value.target.value)}
                        required 
                    />

                    <TextField 
                        id="description" 
                        label="Beschreibung" 
                        onChange={(value) => set_description(value.target.value)}
                        multiline
                    />

                    <FormControlLabel control={<Checkbox onChange={(event) => {set_as_tempalte(event.target.checked)}}/>} label="Als Vorlage speicher" />

                    <Button 
                        variant="contained" 
                        onClick={click_create}
                    >Erstellen</Button>
                </div>

                <div className="template-list">
                    <h1 className="text-center">Vorlagen</h1>
                    <div className="template-list">
                        { templates.map(template => (
                            <TemplateListItem 
                                key={template.id}
                                title={template.title} 
                                text={template.description} 
                            />
                        ))}
                    </div>

                </div>
            </div>
        </div>
    )
}