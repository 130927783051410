import { FormControl, InputLabel, Select, MenuItem, Button } from "@mui/material"
import { Link } from "react-router-dom"

import MyDrawer from "../partials/drawer"

export default function Settings(props) {

    const change_username = (event) => {
        props.set_username(event.target.value)
    }

    const activate_notifications = async () => {
        let sw = await navigator.serviceWorker.ready;
        let push = await sw.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: 'BJdTTnSvDKAsqbk0UbLzcOy-3n9yRuPC-kE9gF3PpY8vT7SpqyupLhBI3Pb88HdcXMaRJP4tqHi9r16xMcTFNDA',
        })
        console.log(JSON.stringify(push))

        /* Send Subscription to Backend */
        fetch(process.env.REACT_APP_API_URL+"/AddSubscription/"+props.username, {
            method: 'POST',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(push)
        })
    }


    return (
        <div className="home-container">
            <MyDrawer />
            <div id="settings-page">       
                <h1 className="App-Title">Settings</h1>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Nutzername</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={props.username}
                        label="Nutzername"
                        onChange={change_username}
                    >
                        <MenuItem value={"Niemand"}>Niemand</MenuItem>
                        <MenuItem value={"Hiltrud"}>Hiltrud</MenuItem>
                        <MenuItem value={"Jannik"}>Jannik</MenuItem>
                        <MenuItem value={"Jürgen"}>Jürgen</MenuItem>
                        <MenuItem value={"Lennard"}>Lennard</MenuItem>
                    </Select>
                </FormControl>
                <Button variant="outlined" sx={{margin: 1}} onClick={() => activate_notifications()}>Benachrichtigungen aktivieren</Button>
                <Link to="/"><Button variant="contained" sx={{margin: 1}}>Speichern</Button></Link>
            </div>
        </div>
    )
}