import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';

import CssBaseline from '@mui/material/CssBaseline';
import Home from "./pages/Home"
import Test from "./pages/Test"
import Settings from "./pages/Settings";
import { useState } from "react";
import CreateTask from "./pages/CreateTask";


const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {

  const read_username_from_storage = () => {
    var username = localStorage.getItem("username");
    if (username == null) {
      username = "Niemand"
    }
    return username
  }

  var [username, set_username] = useState(read_username_from_storage)

  

  const write_username_to_storage = (username) => {
    localStorage.setItem("username", username)
    set_username(username)
  }

  return (
    <div className="App">
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home username={username} set_username={write_username_to_storage} />} />
            <Route path="/test" element={<Test />} />
            <Route path="/settings" element={<Settings username={username} set_username={write_username_to_storage} />} />
            <Route path="/createtask" element={<CreateTask />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
      
    </div>
  );
}

export default App;
