import { useState } from 'react';
import { Dialog, DialogTitle, Card, CardActions, CardContent, Button, Typography, Avatar } from '@mui/material';

import './task-list-item.css'


export default function TaskListItem(props) {

    const [dialog_state, set_dialog_state] = useState(false)

    const animation_delay = props.index * 0.15

    const animation_delay_string = animation_delay + "s"

    const open_dialog = (open) => {
        set_dialog_state(open)
    }

    var avatar_color = "#333333"

    const assign_color_to_avatar = () => {
        if (props.assignee === "Jannik") {
            avatar_color = "#F05454" 
        } 
        else if (props.assignee === "Jürgen") {
            avatar_color = "#406882"
        }
        else if (props.assignee === "Lennard") {
            avatar_color = "#F4722B"
        }
        else if (props.assignee === "Hiltrud") {
            avatar_color = "#ECCE6D"
        }
    }

    assign_color_to_avatar()

    const assign_task = async () => {
        console.log("Assign to "+props.username)
        await fetch(process.env.REACT_APP_API_URL+"/AssignTask", {
            method: 'POST',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },
            body: JSON.stringify({Assignee: props.username, ID: props.id})
        })
        props.fetch_all_tasks()
    }

    const close_task = async () => {
        console.log("Close task")
        await fetch(process.env.REACT_APP_API_URL+"/DeleteTask", {
            method: 'POST',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },
            body: JSON.stringify({ID: props.id})
        })
        open_dialog(false)
        props.fetch_all_tasks()
    }

    return (
        <div className="task_list_item-wrapper" style={{ animationDelay: animation_delay_string }}>
                <Card sx={{ width: "90%" }}>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                        { props.title }
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                        { props.text }
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small" onClick={() => assign_task()}>Mach ich</Button>
                        <Button size="small" onClick={() => open_dialog(true)}>Fertig</Button>
                        <div className="worker-display">
                            <Avatar sx={{ bgcolor: avatar_color }}>{ props.assignee.substring(0,2) }</Avatar>
                        </div>
                    </CardActions>
                </Card>

                <Dialog open={dialog_state} className="dialogbox">
                    <DialogTitle>{ props.title }</DialogTitle>
                    <Typography variant="body1">Möchtest du diese Aufgabe wirklich abschließen?</Typography>
                    <div className="dialog-buttons">
                        <Button variant="contained" onClick={() => open_dialog(false)}>Zurück</Button>
                        <Button variant="contained" onClick={() => close_task()}>Abschließen</Button>
                    </div>
                </Dialog>
        </div>
    )
}