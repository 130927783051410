import { Card, CardContent, Typography, CardActions, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";


export default function TemplateListItem(props) {

    const navigate = useNavigate()

    const add_this_task = () => {
        /* Create Task in Backend */
        fetch(process.env.REACT_APP_API_URL+"/AddTask", {
            method: 'POST',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "Title": props.title,
                    "Description": props.description,
                    "Assignee": "Niemand",
                    "Status": "open",
                })
        })
        /* Navigate back to home */
        .then(navigate("/"))
        .catch( err => {
            console.log(err)
        })
    }

    return (
        <div className="template-list-item">
            <Card sx={{ width: "90%" }}>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                { props.title }
               </Typography>
               <Typography variant="body2" color="text.secondary">
                    { props.text }
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" onClick={add_this_task}>Aufgabe hinzufügen</Button>
            </CardActions>
            </Card>
        </div>
    )
}