import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, AppBar, IconButton, Typography, Toolbar  } from "@mui/material"
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home"
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import packageJson from  "../../package.json"


export default function MyDrawer() {
    const [drawer_state, set_drawer_state] = useState(false)

    const toggleDrawer = (open) => (event) => {
        /* This If Clause prevents the thing from death-looping */
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        /* Chagne drawer state */
        set_drawer_state(open)
    }

    const navigate = useNavigate();

    const navigate_settings = () => {
        console.log("navigate to settings")
        navigate("/settings");
    }

    const navigate_task_overview = () => {
        console.log("navigate to settings")
        navigate("/");
    }

    return (
        <div className="MyDrawer">
            <AppBar position="fixed">
                <Toolbar>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={toggleDrawer(true)}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Family Tasks
                </Typography>
                </Toolbar>
            </AppBar>

            <Drawer
                anchor="left"
                open={drawer_state}
                onClose={toggleDrawer(false)}
            >
                <List>
                    <h1 className="text-center">Menu</h1>
                    <ListItem>
                        <ListItemButton onClick={navigate_task_overview}>
                            <ListItemIcon>
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Aufgaben Übersicht"}/>
                        </ListItemButton>
                    </ListItem>

                    <ListItem>
                        <ListItemButton onClick={navigate_settings}>
                            <ListItemIcon>
                                <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Settings"}/>
                        </ListItemButton>
                    </ListItem>



                </List>
                <div className="spacer" />
                <div className="Drawer-Footer">
                    <a href="https://gitlab.com/JadBlackstone/family-tasks">
                        <img className="drawer-logo" src="/GitLab_Logo.png" alt="GitLab Logo with Link to the Repository"></img>
                    </a>
                    <p>Version { packageJson.version }</p>
                </div>
            </Drawer>
        </div>
    )
}